import React from 'react';

const TermsOfService: React.FC = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p>Effective Date: Sep 17, 2024</p>
      
      <p>Welcome to Ditto Wallet ("we," "our," "us"). By accessing or using our services through our mobile application ("App") or website ("Site"), you agree to comply with and be bound by the following Terms of Service ("Terms"). Please read these Terms carefully. If you do not agree with these Terms, you should not use our services.</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By accessing or using the App or the Site, you acknowledge that you have read, understood, and agree to be bound by these Terms. These Terms apply to all users of the App and the Site.</p>

      <h2>2. Use of the App and Site</h2>
      <p>The App and Site provide software services that allow users to interact with blockchain protocols and access useful information based on their on-chain wallet address. The services are provided "as is" and "as available" without any warranties or guarantees of any kind, either express or implied.</p>

      <h2>3. User Responsibilities</h2>
      <ul>
        <li>You are solely responsible for safeguarding your on-chain wallet and any interactions you make with blockchain protocols using the App or the Site.</li>
        <li>Your use of the App and Site, including any transactions, operations, or interactions you engage in on the blockchain, is at your own risk.</li>
        <li>You must ensure that your use of the App and Site complies with all applicable laws, regulations, and guidelines.</li>
      </ul>

      <h2>4. No Warranty</h2>
      <p>The App and Site are provided on an "as is" and "as available" basis without warranties of any kind, either express or implied. We do not warrant that:</p>
      <ul>
        <li>The App or Site will function uninterrupted or error-free.</li>
        <li>The information provided by the App or Site is accurate, complete, or current.</li>
        <li>The App or Site is free of viruses or other harmful components.</li>
      </ul>

      <h2>5. Assumption of Risk</h2>
      <p>Using the App or Site to interact with blockchain protocols carries inherent risks. By using our services, you acknowledge and agree that:</p>
      <ul>
        <li>You assume all risks related to the use of blockchain technologies, including but not limited to transaction errors, smart contract malfunctions, or market risks.</li>
        <li>We are not liable for any losses, damages, or claims arising from your use of blockchain protocols, including losses due to user error, software malfunctions, technical failures, or regulatory changes.</li>
      </ul>

      <h2>6. Limitation of Liability</h2>
      <p>In no event shall we be liable to you or any third party for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, loss of profits, data, or other intangible losses arising out of or in connection with your use of the App or Site, even if we have been advised of the possibility of such damages.</p>

      <h2>7. Changes to These Terms</h2>
      <p>We reserve the right to modify or replace these Terms at any time. Changes will be effective immediately upon posting within the App and on the Site. Your continued use of the App or Site following the posting of any changes to these Terms constitutes acceptance of those changes.</p>

      <h2>8. Termination</h2>
      <p>We reserve the right to terminate or suspend your access to the App and Site, without notice, for any reason, including breach of these Terms.</p>

      <h2>9. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the federal laws of the United States, without regard to its conflict of law principles.</p>

      <h2>10. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at support@dittowallet.com.</p>
    </div>
  );
};

export default TermsOfService;