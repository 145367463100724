import React from 'react';
import COLORS from '../styles/colors';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.footerContent}>
        <FooterColumn title="Resources" links={[{ text: 'Support', href: 'mailto:support@dittowallet.com' }]} />
        <FooterColumn 
          title="Legal" 
          links={[
            { text: 'Terms of Service', href: '/terms-of-service' },
            { text: 'Privacy Policy', href: '/privacy' },
          ]} 
        />
      </div>
    </footer>
  );
};

interface LinkItem {
  text: string;
  href: string;
}

interface FooterColumnProps {
  title: string;
  links: LinkItem[];
}

const FooterColumn: React.FC<FooterColumnProps> = ({ title, links }) => (
  <div style={styles.footerColumn}>
    {links.map((link, index) => (
      link.href.startsWith('mailto:') ? (
        <a key={index} href={link.href} style={styles.footerLink}>
          {link.text}
        </a>
      ) : (
        <Link key={index} to={link.href} style={styles.footerLink}>
          {link.text}
        </Link>
      )
    ))}
  </div>
);

const styles = {
  footer: {
    backgroundColor: COLORS.darkPurple,
    color: COLORS.white,
    padding: '40px 20px',
    borderTop: `1px solid ${COLORS.primaryGold}`,
  },
  footerContent: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    justifyContent: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  footerColumn: {
    flex: '0 1 auto',
    width: '50%',
    textAlign: 'center' as const,
  },
  columnTitle: {
    fontSize: '18px',
    marginBottom: '15px',
    color: COLORS.white,
  },
  footerLink: {
    display: 'block',
    color: COLORS.white,
    textDecoration: 'none',
    marginBottom: '10px',
    fontSize: '14px',
    transition: 'color 0.3s',
  },
  footerBottom: {
    borderTop: `1px solid ${COLORS.primaryGold}`,
    marginTop: '20px',
    paddingTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap' as const,
  },
  copyright: {
    fontSize: '14px',
  },
  socialLinks: {
    display: 'flex',
  },
  socialLink: {
    color: COLORS.white,
    textDecoration: 'none',
    marginLeft: '20px',
    fontSize: '14px',
    transition: 'color 0.3s',
  },
};

export default Footer;
