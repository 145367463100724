import React from 'react';

const Privacy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        Ditto Wallet ("we", "our", "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile and web application ("App"). By using the App, you agree to the collection and use of information in accordance with this policy.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        The App collects the following information:
      </p>
      <ul>
        <li>
          <strong>On-chain Wallet Address:</strong> We collect and use your on-chain wallet address to provide you with useful information and to allow our AI to perform customized actions for you. This data is essential for the core functionalities of the App.
        </li>
      </ul>

      <h2>2. Use of Your Information</h2>
      <p>
        We use the information we collect for the following purposes:
      </p>
      <ul>
        <li>To display useful information related to your on-chain wallet.</li>
        <li>To provide personalized and customized actions using our AI functionality.</li>
      </ul>

      <h2>3. Use of Google Analytics</h2>
      <p>
        We use Google Analytics to collect data about your interactions with the App. This information is used to understand how you use the App, improve its performance, and enhance user experience. Google Analytics may collect information such as your device type, operating system, usage statistics, and crash data. Google Analytics does not collect personally identifiable information.
      </p>

      <h2>4. No Data Sharing with Third Parties</h2>
      <p>
        We do not share, sell, or otherwise transfer your on-chain wallet address or any other personal data collected through the App with third parties.
      </p>

      <h2>5. Security of Your Information</h2>
      <p>
        We use commercially reasonable methods to protect your information, including encryption and secure storage practices. However, please be aware that no method of electronic storage or transmission over the internet is 100% secure, and we cannot guarantee the absolute security of your data.
      </p>

      <h2>6. Children's Privacy</h2>
      <p>
        Our App does not specifically target individuals under the age of 18. If we learn that we have collected personal data from a child under 18 without verification of parental consent, we will take steps to delete that information promptly.
      </p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. Changes to this Privacy Policy will be posted within the App and will be effective as of the date they are posted. We encourage you to review this Privacy Policy periodically for any updates.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at support@dittowallet.com
      </p>
    </div>
  );
};

export default Privacy;