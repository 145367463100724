import React from 'react';
import sendExample from '../assets/send-example.png';
import assetExample from '../assets/assets-example.png';
import appleDownload from '../assets/Download_on_the_App_Store_Badge.svg'
import COLORS from '../styles/colors';
import { Link } from 'react-router-dom';

const fakeQRCode = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAEXklEQVR42u3dW3LbMAwFUO5/07cfnbidRNZDJEBCZ+ZbxvHDvgJIkKKU7TZOt+3xOkrFxf9/+3//z7/x9H3Hx2dYvZ/r57GNv4/7Pj4+H7/wP3/+fHsAYHiBJeS7d5edmrB6P9f3x/v9XQPg7QGAEQKsHFhC3rqLTk1Yvd/U/fEOYPd+rwAYKcDKgSXkrTvp1ITV+03dH+8Adu/3CoCRAqwcWELeuqNOTVi939T98Q5g936vABgpwMqBJeStO+vUhNX7Td0f7wB27/cKgJECrBxYQt66w05NWL3f1P3xDmD3fq8AGCnAyoEl5K077dSE1ftN3R/vAHbv9wqAkQKsHFhC3rrjTk1Yvd/U/fEOYPd+rwAYKcDKgSXkrTvv1ITV+03dH+8Adu/3CoCRAqwcWELeuoGnJqzeb+r+eAewe79XAIwUYOXAEvLWTTw1YfV+U/fHO4Dd+70CYKQAKweWkLdu5KkJq/ebuj/eAeze7xUAIwVYObCEvHVDT01Yvd/U/fEOYPd+rwAYKcDKgSXkrZt7asLq/abuj3cAu/d7BcBIAVYOLCFv3ehTE1bvN3V/vAPYvd8rAEYKsHJgCXnrpp+asHq/qfvjHcDu/V4BMFKAlQNLyFs3/9SE1ftN3R/vAHbv9wqAkQKsHFhC3noATk1Yvd/U/fEOYPd+rwAYKcDKgSXkrQfh1ITV+03dH+8Adu/3CoCRAqwcWELeejBOTVi939T98Q5g936vABgpwMqBJeStB+TUhNX7Td0f7wB27/cKgJECrBxYQt56UE5NWL3f1P3xDmD3fq8AGCnAyoEl5K0H5tSE1ftN3R/vAHbv9wqAkQKsHFhC3npwTk1Yvd/U/fEOYPd+rwAYKcDKgSXkrQfo1ITV+03dH+8Adu/3CoCRAqwcWELeepBOTVi939T98Q5g936vABgpwMqBJeStB+rUhNX7Td0f7wB27/cKgJECrBxYQt56sE5NWL3f1P3xDmD3fq8AGCnAyoEl5K0H7NSE1ftN3R/vAHbv9wqAkQKsHFhC3nrQTk1Yvd/U/fEOYPd+rwAYKcDKgSXkrQfu1ITV+03dH+8Adu/3CoCRAqwcWELeevBOTVi939T98Q5g936vABgpwMqBJeStA3BqQvfHO4Dd+70CYKQAKweWkLcOwqkJ3R/vAHbv9wqAkQKsHFhC3joQpyZ0f7wD2L3fKwBGCrByYAl56yCcmtD98Q5g936vABgpwMqBJeStA3FqQvfHO4Dd+70CYKQAKweWkLcOxqkJ3R/vAHbv9wqAkQKsHFhC3joYpyZ0f7wD2L3fKwBGCrByYAl562CcmtD98Q5g936vABgpwMqBJeStg3FqQvfHO4Dd+70CYKQAKweWkLcOxqkJ3R/vAHbv9wqAkQKsHFhC3joYpyZ0f7wD2L3fKwBGCrByYAl562CcmtD98Q5g936vABgpwMqBJeStg3FqQvfHO4Dd+70CYKQAKweWkLcOxqkJ3R/vAHbv9wqAkQKsHFhC3joYpyZ0f7wD2L3fKwBGCrByYAl562CcmtD98Q5g936vABgpwMqBJeStg3FqQvfHO4Dd+70CYKQAKweWkLcOxqkJ3R/vAHbv9+oXZoVjvlr7ZcEAAAAASUVORK5CYII=';

const Product: React.FC = () => {
  return (
    <div style={styles.product}>
      <div style={styles.leftPart}>
        <div style={styles.imageStack}>
          <img src={assetExample} alt="Asset Example" style={styles.exampleImage} />
          <img src={sendExample} alt="Send Example" style={styles.overlappingImage} />
        </div>
      </div>
      <div style={styles.rightPart}>
        <div style={styles.downloadBox}>
          <h2 style={styles.downloadTitle}>Crypto wallet with AI assistant</h2>
          <h3 style={styles.downloadSubTitle}>Easiest way to manage all your digital assets</h3>
          {/* <div style={styles.downloadContent}>
            <img src={fakeQRCode} alt="QR Code" style={styles.qrCode} />
            <a href="https://apps.apple.com/your-app-link" target="_blank" rel="noopener noreferrer">
              <img src={appleDownload} alt="Download on the App Store" style={styles.appStoreBadge} />
            </a>
          </div> */}
          <Link to="/chat" className="chat-button" style={styles.chatButton}>Try the web version</Link>
        </div>
      </div>
    </div>
  );
};

const styles = {
  product: {
    display: 'flex',
    padding: '40px 20px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  leftPart: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightPart: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageStack: {
    position: 'relative' as const,
    width: '80%',
  },
  exampleImage: {
    width: '70%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  overlappingImage: {
    position: 'absolute' as const,
    top: '0%',
    left: '40%',
    width: '70%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
  },
  downloadBox: {
    border: `2px solid ${COLORS.primaryPurple}`,
    borderRadius: '12px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    height: '100%', // Match the height of the image stack
    boxSizing: 'border-box' as const,
  },
  downloadTitle: {
    fontSize: '20px',
    marginBottom: '10px',
  },
  downloadSubTitle: {
    fontSize: '14px',
    marginBottom: '20px',
    fontWeight: 'normal'
  },
  downloadContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  appStoreBadge: {
    width: '120px',
    height: 'auto',
    marginLeft: '10px',
  },
  qrCode: {
    width: '150px',
    height: '150px',
    border: '1px solid #ccc',
    backgroundColor: '#f0f0f0',
  },
  chatButton: {
    marginTop: 'auto',
    padding: '10px 20px',
    backgroundColor: COLORS.primaryPurple,
    color: 'white',
    textDecoration: 'none',
    borderRadius: '5px',
    textAlign: 'center' as const,
  },
};

export default Product;
