import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Chat from './components/Chat';
import './App.css';
import merge from 'lodash.merge';
import iconSrc from './assets/icon.png';

import '@rainbow-me/rainbowkit/styles.css';
import {
  RainbowKitProvider,
  ConnectButton,
  Theme,
  lightTheme
} from '@rainbow-me/rainbowkit';

import Product from './components/Product';
import Footer from './components/Footer';
import Privacy from './components/Privacy'; // Add this line
import TermsOfService from './components/TermsOfService'; // Add this line

// New Home component
const Home = () => (
  <div className="landing-content">
    <Product />
  </div>
);

// New ConnectButtonWrapper component
const ConnectButtonWrapper = () => {
  const location = useLocation();
  return location.pathname === '/chat' ? (
    <ConnectButton showBalance={false} chainStatus={'icon'} />
  ) : null;
};

const App = () => {

  const simpleTheme = merge(lightTheme(), {
    shadows: {
      connectButton: '#FFFFFF',
      dialog: '#FFFFFF',
      profileDetailsAction: '#FFFFFF',
      selectedOption: '#FFFFFF',
      selectedWallet: '#FFFFFF',
      walletLogo: '#FFFFFF',
    },
    colors: {
      connectButtonInnerBackground: '#FFFFFF',
    }
  } as Theme);

  return (
    <Router>
      <RainbowKitProvider theme={simpleTheme} modalSize="compact">
        <div className="App">
          <header>
            <div className="logo">
              <Link to="/">
                <img src={iconSrc} alt="Ditto Icon" className="logo-icon" />
                <span>Ditto</span>
              </Link>
            </div>
            <div className="connect-button-container">
              <ConnectButtonWrapper />
            </div>
          </header>
          <main className="landing-layout">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="/privacy" element={<Privacy />} /> {/* Add this line */}
              <Route path="/terms-of-service" element={<TermsOfService />} /> {/* Add this line */}
            </Routes>
          </main>
          <Footer />
        </div>
      </RainbowKitProvider>
    </Router>
  );
};

export default App;