import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Message } from '../types';
import COLORS from '../styles/colors';

interface MessageProps {
  message: Message;
}

const MessageComponent: React.FC<MessageProps> = ({ message }) => {
  const formattedTime = message.timestamp.toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });

  return (
    <div style={{
      ...styles.message,
      ...(message.sender === 'user' ? styles.userMessage : styles.botMessage)
    }}>
      <div style={styles.messageContent}>
        <ReactMarkdown
          components={{
            a: ({ node, ...props }) => (
              <a target="_blank" rel="noopener noreferrer" {...props} style={styles.link} />
            ),
            img: ({ node, ...props }) => (
              <img {...props} style={styles.image} />
            ),
          }}
        >
          {message.text}
        </ReactMarkdown>
        {message.qrCodeUrl && (
          <img
            src={message.qrCodeUrl}
            alt="QR Code"
            style={styles.qrCode}
          />
        )}
      </div>
      <div style={{
        ...styles.messageTime,
        ...(message.sender === 'user' ? styles.userMessageTime : styles.botMessageTime)
      }}>
        {formattedTime}
      </div>
    </div>
  );
};

const styles = {
  message: {
    marginBottom: '10px',
    fontSize: '12px',
    color: COLORS.white,
  },
  userMessage: {
    alignSelf: 'flex-end',
  },
  botMessage: {
    alignSelf: 'flex-start',
  },
  messageContent: {
    padding: '10px 15px',
  },
  link: {
    color: COLORS.primaryGold,
    textDecoration: 'underline',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  qrCode: {
    width: '300px',
    height: '300px',
    marginTop: '10px',
  },
  messageTime: {
    fontSize: '12px',
    marginTop: '5px',
    opacity: 0.7,
  },
  userMessageTime: {
    textAlign: 'right' as const,
    paddingRight: '5px',
  },
  botMessageTime: {
    textAlign: 'left' as const,
    paddingLeft: '5px',
  },
};

export default MessageComponent;